import React, {useState, useEffect} from 'react';

import './Pagination.scss';

const Pagination = ({
  totalPages = 10, 
  activePage = 1, 
  setActivePage = () => { },
  className = ''
}) => {
  const numberOfPages = Array.from({length: totalPages}, (_, i) => i + 1);

  const [currentButton, setCurrentButton] = useState(activePage);
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([])
  useEffect(() => {
    let tempNumberOfPages = [...arrOfCurrButtons];

    let dotsInitial = '...'
    let dotsLeft = '... '
    let dotsRight = ' ...'

    if (numberOfPages.length < 6) {
      tempNumberOfPages = numberOfPages;
    }

    else if (currentButton >= 1 && currentButton <= 3) {
      tempNumberOfPages = [1, 2, 3, 4, dotsInitial, numberOfPages.length];
    }

    else if (currentButton === 4) {
      const sliced = numberOfPages.slice(0, 5);
      tempNumberOfPages = [...sliced, dotsInitial, numberOfPages.length];
    } else if (currentButton > 4 && currentButton < numberOfPages.length - 2) {
      const sliced1 = numberOfPages.slice(currentButton - 2, currentButton);
      const sliced2 = numberOfPages.slice(currentButton, currentButton + 1);
      tempNumberOfPages = ([1, dotsLeft, ...sliced1, ...sliced2, dotsRight, numberOfPages.length]);
    } else if (currentButton > numberOfPages.length - 3) {
      const sliced = numberOfPages.slice(numberOfPages.length - 4);
      tempNumberOfPages = ([1, dotsLeft, ...sliced]);
    } else if (currentButton === dotsInitial) {
      setCurrentButton(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    } else if (currentButton === dotsRight) {
      setCurrentButton(arrOfCurrButtons[3] + 2);
    } else if (currentButton === dotsLeft) {
      setCurrentButton(arrOfCurrButtons[3] - 2);
    }
    setArrOfCurrButtons(tempNumberOfPages);
    setActivePage(currentButton);
  }, [currentButton]);

  return (
    <div className={`Pagination ${className}`}>
      <div
        className={`circle-btn-left ${currentButton === 1 ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          setCurrentButton(prev => prev <= 1 ? prev : prev - 1)
        }}
      />
      {arrOfCurrButtons.map(((item, index) => {
        return <div
          key={index}
          className={`page-number${currentButton === item ? ' active' : ''}`}
          onClick={() => setCurrentButton(item)}
        >
          {item}
        </div>
      }))}
      <div
        className={`circle-btn-right ${currentButton === numberOfPages.length ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          setCurrentButton(prev => prev >= numberOfPages.length ? prev : prev + 1)
        }}
      />
    </div>
  )
}

export default Pagination;
