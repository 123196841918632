import React, {useCallback, useRef} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import './BlogPost.scss';
import "swiper/css";
import {useState} from 'react';

const BlogPost = () => {
  const [slides] = useState(
    Array.from({length: 6}).map((_, index) => ({
      title: `Slide ${index + 1}`,
      body: 'We suply good stricly and as close as possible to your requirements and according to MOA and ISSA catalogue descriptions. We suply good stricly and as close as possible to your requirements and according to MOA and ISSA catalogue descriptions.',
    }))
  );

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="BlogPost">
      <div className="heading1 font-extrabold gallery-header">BLOG & POST</div>
      <div className="carousel-container">
        <div className="circle-btn circle-btn-left" onClick={handlePrev}></div>
        <Swiper
          ref={sliderRef}
          slidesPerView={1}
          className='carousel'
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          {slides.map((slideContent, index) => (
            <SwiperSlide key={index} >
              <div className="slide-content">
                <div className='body font-bold slide-title'>{slideContent.title}</div>
                <div className='body font-regular slide-body'>{slideContent.body}</div>
                <a className='body font-regular readmore-btn' href='https://google.com/' rel="noreferrer" target='_blank'>read more</a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="circle-btn circle-btn-right" onClick={handleNext} />
      </div>
    </div>
  )
};

export default BlogPost;