import React from 'react';
import AnimatedNumber from 'react-animated-number'

import './CompanyProfile.scss';
import {shortenLongNum} from '../../../../utils/common';
import RightButtonOvalButton from '../../../../components/RightBottomOvalButton/RightButtonOvalButton';
import ScrollAnimation from 'react-animate-on-scroll';


const CompanyProfile = () => {

  return (
    <section className="CompanyProfile">
      <div className="container">
        <ScrollAnimation animateOnce animateIn='animate__fadeInLeft' className="left-content">
          <p className='font-bold'>Leader in Marine Safety Equipment & Services in
            Indonesia’s Marine & Offshore Industry since 2002.</p>

          <p>PT GABRIEL INTI MARINDO aims to become the leading company of marine
            and shipping vessel services in the region by continuously improving the
            quality of their products & investing in the latest technologies. PT GABRIEL INTI
            MARINDO provides several services for your marine and shipping needs:</p>
          <ul>
            <li>
              <p>
                <span className='font-semibold'>Ship Supplies: </span>
                PT GABRIEL INTI MARINDO provides a wide range of
                high quality marine products, including marine safety equipment,
                synthetic fiber ropes, fire fighting equipment, liferafts and lifeboats,
                navigation electronics, communication electronics, and miscellaneous
                supporting equipment. PT GABRIEL INTI MARINDO ensures that all
                supplies meet the highest quality standard.
              </p>
            </li>
            <li>
              <p>
                <span className='font-semibold'>Shipping Repairs: </span>
                PT GABRIEL INTI MARINDO offers a variety of repair
                services to ships of all sizes and types. PT GABRIEL INTI MARINDO’s
                technicians ensure that repairs are completed to the highest standards
                and safety regulations.
              </p>
            </li>
            <li>
              <p>
                <span className='font-semibold'>Inspections: </span>
                PT Gabriel Inti Marindo offers a comprehensive inspection
                service that covers all aspects of a ship's safety and operation. All
                inspections are carried out in accordance with industry standards and
                regulations to make sure safety is always on top of the priority.
              </p>
            </li>
            <li>
              <p>
                <span className='font-semibold'>Vessel Spare Parts: </span>
                The company provides high-quality spare parts for ships, including engine parts, electrical parts, navigation equipment, and safety equipment.
              </p>
            </li>
          </ul>

          <p>
            PT GABRIEL INTI MARINDO’s mission is to provide its customers with the highest quality marine and shipping services in the industry.
            PT GABRIEL INTI MARINDO is committed to delivering safety with efficient, reliable, and cost-effective solutions
          </p>
          <RightButtonOvalButton href={require('../../../../assets/company-profile.pdf')} target="_blank" >
            Our <b>Company Profile</b>
          </RightButtonOvalButton>
        </ScrollAnimation>
        <ScrollAnimation animateOnce animateIn='animate__fadeInRight' className="right-content">
          <div className="box">
            <AnimatedNumber
              value={20}
              className="subtitle1 font-extrabold"
              formatValue={n => shortenLongNum(n.toFixed()) + '+'}
              duration={1000}
            />
            <div className="body font-bold">Years of Experiences</div>
          </div>
          <div className="box">
            <AnimatedNumber
              value={100}
              className="subtitle1 font-extrabold"
              formatValue={n => shortenLongNum(n.toFixed()) + '+'}
              duration={1000}
            />
            <div className="body font-bold">Partnership with
              trusty clients</div>
          </div>
          <div className="box">
            <AnimatedNumber
              value={5000}
              className="subtitle1 font-extrabold"
              formatValue={n => shortenLongNum(n.toFixed()) + '+'}
              duration={1000}
            />
            <div className="body font-bold">Product
              Assortments</div>
          </div>
          <div className="box">
            <AnimatedNumber
              value={40000}
              className="subtitle1 font-extrabold"
              formatValue={n => shortenLongNum(n.toFixed()) + '+'}
              duration={1000}
            />
            <div className="body font-bold">Services
              Delivered</div>
          </div>
        </ScrollAnimation>
      </div>
    </section>
  )
};

export default CompanyProfile;
