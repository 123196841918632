import React, {useEffect, useState} from 'react';
import CustomScrollbar from '../../components/CustomScrollbar/CustomScrollbar';
import PageWrapper from '../../Layout/PageWrapper/PageWrapper';
import CategorySection from './sections/Category/Category';
import BrandSection from './sections/Brand/Brand';
import Pagination from '../../components/Pagination/Pagination';
import './Product.scss';
import ProductList from './sections/ProductList/ProductList';
import ParallaxMainHeadline from '../../components/ParallaxMainHeadline/ParallaxMainHeadline';
import ProductHeadlineJPG from '../../assets/images/Image-productpage-header.jpg'
import {logError} from '../../utils/logger';
import callApi from '../../utils/api';
import {getCategories} from '../../API/categories';
import {getBrands} from '../../API/brands';
import {getProducts} from '../../API/products';
import {API_STATUS_STATE} from '../../constants';

const Product = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [categoryListStatus, setCategoryListStatus] = useState(API_STATUS_STATE);

  const [brandList, setBrandList] = useState([]);
  const [brandListStatus, setBrandListStatus] = useState(API_STATUS_STATE);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [productList, setProductList] = useState({
    data: [],
    currentPage: 1,
    totalItems: 0,
    totalPages: 0,
  });
  const [productListStatus, setProductListStatus] = useState(API_STATUS_STATE);
  const [activeProductPage, setActiveProductPage] = useState(1);

  const [isAscending, setIsAscending] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState({
    category: null,
    subCategory: null,
  });

  const fetchCategories = async () => {
    setCategoryListStatus(_prevStat => ({
      ..._prevStat,
      ...API_STATUS_STATE,
      isLoading: true,
    }));
    try {
      const response = await callApi(getCategories());
      const {data: {data}, status} = response;
      if (status === 200) {
        setCategoryList(data);
        setSelectedCategory({
          category: data[0].id,
          subCategory: data[0].subCategories[0].id
        });
      }
      setCategoryListStatus(_prevStat => ({
        ..._prevStat,
        isFetched: true,
      }));
    } catch (error) {
      setCategoryListStatus(_prevStat => ({
        ..._prevStat,
        isError: true,
        errorMessage: error,
      }));
      logError(error);
    }
    setCategoryListStatus(_prevStat => ({
      ..._prevStat,
      isLoading: false,
    }));
  }

  const fetchBrands = async () => {
    setBrandListStatus(_prevStat => ({
      ..._prevStat,
      ...API_STATUS_STATE,
      isLoading: true,
    }));
    try {
      const response = await callApi(getBrands());
      const {data: {data}, status} = response;
      if (status === 200) {
        setBrandList(data);
      }
      setBrandListStatus(_prevStat => ({
        ..._prevStat,
        isFetched: true,
      }));
    } catch (error) {
      setBrandListStatus(_prevStat => ({
        ..._prevStat,
        isError: true,
        errorMessage: error,
      }));
      logError(error)
    }
    setBrandListStatus(_prevStat => ({
      ..._prevStat,
      isLoading: false,
    }));
  }

  const onMountFetchData = async () => {
    await fetchCategories();
    await fetchBrands();
  }

  const delay = async (ms) => {
    return new Promise((res) => {
      setTimeout(() => {
        res();
      }, ms);
    })
  };
  const fetchProductData = async (brandId, subCategoryId, page, sortBy = 'asc') => {
    setProductListStatus(_prevStat => ({
      ..._prevStat,
      ...API_STATUS_STATE,
      isLoading: true,
    }));
    
    await delay(500);
    try {
      const getProductsQuery = {};
      if (brandId) {
        getProductsQuery.brandId = brandId;
      }
      if (subCategoryId) {
        getProductsQuery.subCategoryId = subCategoryId;
      }
      if (page) {
        getProductsQuery.page = page;
      }
      if (sortBy) {
        getProductsQuery.sortBy = sortBy;
      }
      const response = await callApi(getProducts(getProductsQuery));
      const {data: {data, currentPage, totalItems, totalPages}, status} = response;
      const productList = {
        data,
        currentPage,
        totalItems,
        totalPages
      }

      if (status === 200) {
        setProductList(productList);
      }
      setProductListStatus(_prevStat => ({
        ..._prevStat,
        isFetched: true,
      }));
    } catch (error) {
      setProductListStatus(_prevStat => ({
        ..._prevStat,
        isError: true,
        errorMessage: error,
      }));
      logError(error)
    }
    setProductListStatus(_prevStat => ({
      ..._prevStat,
      isLoading: false,
    }));
  }

  useEffect(() => {
    if (brandListStatus.isFetched && categoryListStatus.isFetched) {
      fetchProductData(
        selectedBrands,
        selectedCategory.subCategory,
        activeProductPage,
        isAscending ? 'asc' : 'desc'
      );
    }
  }, [
    brandListStatus.isFetched,
    categoryListStatus.isFetched,
    selectedBrands,
    selectedCategory,
    activeProductPage,
    isAscending,
  ]);
  useEffect(() => {
    onMountFetchData();
  }, []);


  return (
    <PageWrapper>
      <CustomScrollbar>
        <div className="Product">

          <ParallaxMainHeadline imgSrc={ProductHeadlineJPG} imgAlt={'gallery-img'} name="PRODUCTS" />

          <div className="container product-content-container">
            <div className="sidebar">
              <CategorySection
                isLoading={categoryListStatus.isLoading}
                list={categoryList}
                onClickCategory={(selectedCategory) => {
                  setSelectedCategory(selectedCategory);
                  setSelectedBrands([])
                }}
                selectedCategory={selectedCategory}
              />

              <BrandSection
                isLoading={brandListStatus.isLoading}
                brands={brandList}
                selectedBrands={selectedBrands}
                onClickBrand={(brand) => {
                  setSelectedBrands(_selectedBrands => {
                    const tempSelectedBrands = [..._selectedBrands];
                    const selectedPreviouslyIndex = tempSelectedBrands.indexOf(brand.id);
                    if (selectedPreviouslyIndex !== -1) {
                      tempSelectedBrands.splice(selectedPreviouslyIndex, 1);
                    } else {
                      tempSelectedBrands.push(brand.id)
                    }
                    return tempSelectedBrands;
                  })
                }}
                onClickClear={() => {setSelectedBrands([])}}
                isActiveClear={selectedBrands.length > 0}
              />
            </div>
            <div className="main">
              <div className="main-content">
                <div className="sort-container">
                  <div className="font-semibold body">Sort By:</div>
                  <div className="sorting-dropdown"
                    onClick={() => setIsAscending(asc => !asc)}
                  >
                    {isAscending ? 'A - Z' : 'Z - A'}
                    <div className='chevron' ascending={isAscending ? "true" : "false"}>v</div >
                  </div>
                </div>
                <ProductList
                  isLoading={productListStatus.isLoading}
                  data={productList.data}
                />
                <Pagination
                  className='pagination-container'
                  activePage={activeProductPage}
                  setActivePage={setActiveProductPage}
                  totalPages={productList.totalPages || 1}
                />
              </div>

            </div>
          </div>


        </div>
      </CustomScrollbar>
    </PageWrapper>
  )

};

export default Product;