import React from 'react';
import PageWrapper from '../../Layout/PageWrapper/PageWrapper';
import BlogPost from './sections/BlogPost/BlogPost';
import './Gallery.scss';
import ParallaxMainHeadline from '../../components/ParallaxMainHeadline/ParallaxMainHeadline';
import Pagination from '../../components/Pagination/Pagination';
import DataTable from 'react-data-table-component';
import {AiOutlineEye, AiOutlineDownload} from 'react-icons/ai'
import {useState} from 'react';
import CustomScrollbar from '../../components/CustomScrollbar/CustomScrollbar';
import GalleryHeadlineJPG from '../../assets/images/Image-gallerypage-header.jpg'
import CompanyProfilePDF from '../../assets/company-profile.pdf';

const Gallery = () => {

  const [activeGalleryPage, setActiveGalleryPage] = useState(1);
  const [activeDownloadPage, setActiveDownloadPage] = useState(1);
  return (
    <PageWrapper>
      <CustomScrollbar>
        <div className="Gallery">
          <ParallaxMainHeadline
            imgSrc={GalleryHeadlineJPG}
            imgAlt={'gallery-img'}
            name="GALLERY"
          />
          <div className="container gallery-container">
            <div className="gallery-grid">
              {new Array(9).fill('').map((e, i) => (
                <div className="gallery-item" key={i}>
                  <img src={`https://placehold.co/100?text=img`} alt="" />
                </div>
              ))}
            </div>
          </div>
          <Pagination
            className="gallery-pagination"
            totalPages={20}
            activePage={activeGalleryPage}
            setActivePage={setActiveGalleryPage}
          />
          <div className="container gallery-container">
            <div className="heading1 font-extrabold gallery-header">DOWNLOAD CENTER</div>
            <div className="download-table">
              <DataTable
                columns={
                  [
                    {
                      name: "Title",
                      selector: (row) => row.title,
                    },
                    {
                      name: "View",
                      button: true,
                      cell: (row) => (
                        <button
                          className="icon-btn"
                          onClick={(e) => {
                            const {pdf} = row;
                            if (pdf) {
                              window.open(CompanyProfilePDF, '_blank');
                            }
                          }}
                        >
                          <AiOutlineEye className='table-icon' size="3x" />
                        </button>
                      ),
                    },
                    {
                      name: "Download",
                      button: true,
                      cell: (row) => (
                        <button
                          className="icon-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            const {pdf} = row;
                            if (pdf) {
                              fetch(CompanyProfilePDF).then(response => {
                                response.blob().then(blob => {
                                  const fileURL = window.URL.createObjectURL(blob);
                                  let alink = document.createElement('a');
                                  alink.href = fileURL;
                                  alink.download = `${row.title}.pdf`;
                                  alink.click();
                                })
                              })
                            }
                          }}
                        >
                          <AiOutlineDownload className='table-icon' size="3x" />
                        </button>
                      ),
                    },
                  ]
                }
                data={[
                  {
                    index: 0,
                    title: 'Company Profile',
                    pdf: 'company-profile.pdf'
                  },
                ]} />
            </div>
          </div>
          <Pagination
            className="gallery-pagination"
            totalPages={1}
            activePage={activeDownloadPage}
            setActivePage={setActiveDownloadPage}
          />
          <BlogPost />
        </div>
      </CustomScrollbar>
    </PageWrapper>
  )

};

export default Gallery;