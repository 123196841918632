import React from 'react';
import {ParallaxBanner} from 'react-scroll-parallax';
import './ParallaxMainHeadline.scss';

const ParallaxMainHeadline = (props) => {
  const {imgSrc, name} = props;

  return (
    <div className='ParallaxMainHeadline'>
      <div className="overlay" />
      <ParallaxBanner
        layers={[
          {
            image: imgSrc,
            translateY: [0, 40],
            shouldAlwaysCompleteAnimation: true,
          },
        ]}
        className='ParallaxMainHeadline-banner'
      />
      <div className='ParallaxMainHeadline-content'>
        <div className='ParallaxMainHeadline-content-container'>
          <div className="product-text heading1 font-extrabold">
            {name}
          </div>
        </div>
      </div>
    </div>
  )
};

export default ParallaxMainHeadline;
