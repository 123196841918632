import React, {useEffect, useRef, useState} from 'react';
import {NavLink} from 'react-router-dom';
import useOutsideClick from '../../hooks/useOutsideClick';
import {classNameCallback} from '../../utils/common';
import {mainNavigation} from '../../constants/navigation';

import './Navbar.scss';
import Dropdown from '../Dropdown/Dropdown';

const Navbar = (props) => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isDropdown, setIsDropdown] = useState(false);
  const navbarRef = useRef(null);
  useOutsideClick(navbarRef, () => {
    setIsDropdown(false)
  })
  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      setIsDropdown(false);
      if (window.scrollY > lastScrollY) {
        setShow(false);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div className={`navbar ${!show ? 'nav-hide' : ''}`} ref={navbarRef}>
      <div className='nav-container'>
        <div className="nav-dropdown-container">
          <div className='nav-logo'>
            <img src={require('../../assets/icons/logo.png')} />
            <div className="nav-text body font-bold">
              <span>MARINDO JAYA</span>
              <span> | </span>
              <span className="body font-regular">PT GABRIEL INTI MARINDO</span>
            </div>
          </div>
          <div className={`dropdown-icon ${isDropdown ? 'dropdown' : ''}`} onClick={() => {
            setIsDropdown(isDrop => !isDrop);
          }}>
            <div className="icon">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div className={`nav-items ${isDropdown ? 'dropdown-items' : ''}`}>
          {mainNavigation.map(({to, name}, i) => {
            return <NavLink onClick={() => setIsDropdown(false)} key={i} to={to} className={classNameCallback('nav-item body')}>{name}</NavLink>
          })}
        </div>
      </div>
    </div>
  )
};

export default Navbar;