import React, {useRef} from 'react';
import CustomScrollbar from '../../components/CustomScrollbar/CustomScrollbar';
import PageWrapper from '../../Layout/PageWrapper/PageWrapper';
import {useEffect} from 'react';
import {useState} from 'react';
import {ParallaxBanner} from 'react-scroll-parallax';
import RightButtonOvalButton from '../../components/RightBottomOvalButton/RightButtonOvalButton';
import OverlayedButton from '../../components/OverlayedButton/OverlayedButton';
import {contactUsProductList, productList, servicesList, specializationList} from '../../constants';
import ScrollAnimation from 'react-animate-on-scroll';
import './Home.scss';

const Home = () => {
  const texts = ["Navigation", "Communication", "Safety"];
  const delay = 3000;
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isTextVisible, setIsTextVisible] = useState(true);
  const [isActiveEquipment, setIsActiveEquipment] = useState(true);

  const [currProductIndexWindow, setCurrProductIndexWindow] = useState([0, 1]);
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(0);

  const footerRef = useRef(null);
  const footerHeight = footerRef.current ? footerRef.current.offsetHeight : 600;

  const onPressNextBtn = (isNext = true) => {
    if (isNext) {
      setCurrProductIndexWindow(([first, second]) => {
        const nextFirst = first + 1;
        const nextSecond = second + 1;
        return [
          nextFirst > productList.length - 1 ? 0 : nextFirst,
          nextSecond > productList.length - 1 ? 0 : nextSecond
        ];
      });
    } else {
      setCurrProductIndexWindow(([first, second]) => {
        const nextFirst = first - 1;
        const nextSecond = second - 1;
        return [
          nextFirst < 0 ? productList.length - 1 : nextFirst,
          nextSecond < 0 ? productList.length - 1 : nextSecond
        ];
      });
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTextVisible(false);
      setTimeout(() => {
        setCurrentTextIndex(prevIndex => {
          return prevIndex >= texts.length - 1 ? 0 : prevIndex + 1
        });
        setIsTextVisible(true);
      }, 500);
    }, delay);
    return () => clearInterval(interval);
  }, [delay]);

  const _renderSwitchContent = () => {
    if (isActiveEquipment) {
      return (
        <div className="equipment">
          <p>
            <span className='font-semibold'>PT GABRIEL INTI MARINDO</span> is a marine ship chandler company
            specializing in ship supply services. Located in Jakarta, Indonesia,
            we provide several services for your marine and shipping needs.
          </p>
          <p>
            Our mission is to provide each of our clients with the highest quality
            marine and shipping services. We are committed to provide safety
            with efficient, reliable, and cost-effective solutions.
          </p>
        </div>
      )
    } else {
      return (
        <div className="specialization">
          {specializationList.map((specializationItem, specializationIndex) => {
            return (
              <div className="specialization-wrap-border" key={`specialization-container${specializationIndex}`}>
                <div className="specialization-container">
                  <div className="specialization-img">
                    <img src={specializationItem.imgSrc} alt={specializationItem.title} />
                  </div>
                  <div className="specialization-text">{specializationItem.title}</div>
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  const _renderAbout = () => {
    return (
      <div className='home-container about-content container'>
        <ScrollAnimation animateIn='animate__fadeInDown' className="product-text heading1 font-extrabold">ABOUT</ScrollAnimation>

        <div className="content-flex">
          <ScrollAnimation animateIn='animate__fadeInLeft' className='image-container'>
            <img src={require('../../assets/images/about-profile.jpeg')} alt="about-profile" />
          </ScrollAnimation>
          <ScrollAnimation animateIn='animate__fadeInRight' className="switcher-content-container ">
            <div className="row">
              <div className={`switch subtitle3 ${isActiveEquipment ? 'active' : ''}`} onClick={() => setIsActiveEquipment(true)}><span>
                Your Partner in <br /> Marine Equipments</span></div>
              <div className={`switch subtitle3 ${!isActiveEquipment ? 'active' : ''}`} onClick={() => setIsActiveEquipment(false)}>
                <span>Our<br /> Specialization</span>
              </div>
            </div>
            <div className="switch-content">
              {_renderSwitchContent()}
              <RightButtonOvalButton href={require('../../assets/company-profile.pdf')} target="_blank" >
                Our <b>Company Profile</b>
              </RightButtonOvalButton>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    )
  }
  const _renderProduct = () => {
    return (
      <div className="home-container container products">
        <ScrollAnimation animateIn='animate__fadeInLeft' className="content-sliders-container">
          <div className="circle-btn-left left" onClick={() => onPressNextBtn(false)} />
          <div className="slider-img-container">
            <div className="slider-img left-img">
              <div className="slider-img-overlay">
                <div className="product-title subtitle2 font-semibold">{productList?.[currProductIndexWindow[0]]?.title}</div>
              </div>
              {productList.map((e, i) => {
                return <img
                  key={`first-${i}`}
                  className={currProductIndexWindow[0] === i ? '' : 'hidden-product-img'}
                  src={e.imgSrc} alt="product-img"
                />
              })}
            </div>
            <div className="slider-img">
              <div className="slider-img-overlay">
                <div className="product-title subtitle2 font-semibold">{productList?.[currProductIndexWindow[1]]?.title}</div>
              </div>
              {productList.map((e, i) => {
                return <img
                  key={`second-${i}`}
                  className={currProductIndexWindow[1] === i ? '' : 'hidden-product-img'}
                  src={e.imgSrc} alt="product-img"
                />
              })}
            </div>
          </div>
          <div className="circle-btn-right right" onClick={() => onPressNextBtn()} />
        </ScrollAnimation>
        <ScrollAnimation animateIn='animate__fadeInRight' className="product-container">
          <div className="title-text subtitle3 font-semibold">
            Tailored-made
          </div>
          <div className="product-text heading1 font-extrabold">PRODUCTS</div>
          <div className="product-description body">
            <p>
              MARINDO JAYA provides goods strictly
              as close as possible to your requirements
              according to IMPA and ISSA.
            </p>
            <RightButtonOvalButton href="/product">
              <span className='our-text body'>More of {" "}<span className='cp-text body font-semibold'>Our Products</span></span>
            </RightButtonOvalButton>
          </div>
        </ScrollAnimation>
      </div>
    )
  }
  const _renderServices = () => {
    return (
      <div className="home-container container services">
        <ScrollAnimation animateIn='animate__fadeInDown'>
          <div className="title-text text-align-center subtitle3 font-semibold">
            Our Offered
          </div>
          <div className="product-text heading1 font-extrabold">SERVICES</div>
        </ScrollAnimation>
        <div className="services-container">
          <ScrollAnimation className="services-list" animateIn='animate__fadeInLeft'>
            {servicesList.map((serviceItem, serviceIndex) => {
              return (
                <OverlayedButton
                  key={`service-${serviceIndex}`}
                  active={serviceIndex === selectedServiceIndex}
                  onClick={() => {
                    setSelectedServiceIndex(serviceIndex)
                  }}
                  borderBottom
                >
                  {serviceItem.title}
                </OverlayedButton>
              )
            })}
          </ScrollAnimation>
          <ScrollAnimation className="services-detail" animateIn='animate__fadeInRight'>
            {servicesList.map((serviceItem, serviceImgIndex) => {
              return (
                <div key={serviceImgIndex} className={`service-img ${serviceImgIndex === selectedServiceIndex ? 'show-img' : ''}`} >
                  <img src={serviceItem.description.imgSrc} alt={serviceItem.title} />
                  <div className="overlay">
                    <div className="service-title heading2 font-semibold">{serviceItem.title}</div>
                    <div className="service-list body">
                      {serviceItem.description.list.map(((list, listIndex) => {
                        return (
                          <div className="list" key={`list-${listIndex}`}>
                            <div className="dot">•</div>
                            <div className='list-content'>{list}</div>
                          </div>
                        )
                      }))}
                    </div>
                  </div>
                </div>
              )
            })}
          </ScrollAnimation>
        </div>

      </div>
    )
  }
  return (
    <PageWrapper>
      <CustomScrollbar>
        <div className='Home page-container'>
          <div className="absolute-container">
            <div className='parallax-container'>
              <div className="overlay" />
              <ParallaxBanner
                layers={[{
                  image: require('../../assets/images/homepage.jpg'),
                  speed: -20,
                  alt: 'homepage-img'
                }]}
                className="parallax-banner"
              >
              </ParallaxBanner>
              <div className='parallax-content'>
                <div className='content-container'>
                  <div className='head-sentence subtitle1'>Everything You Need</div>
                  <div className={`tag-line font-extrabold text text-${currentTextIndex} ${!isTextVisible ? "text-hidden" : ""}`}>
                    {texts[currentTextIndex]}
                  </div>
                  <div className='description subtitle3'>
                    Prodiving
                    <span className='font-bold'>Nationwide Shipping</span>
                    for
                    <span className='font-bold'>Marine Equipments</span>
                    and
                    <span className='font-bold'>Repair Service</span>
                  </div>
                </div>

              </div>
            </div>
            {_renderAbout()}

            {_renderProduct()}

            {_renderServices()}
          </div>

        </div>
      </CustomScrollbar>
    </PageWrapper>
  )

};

export default Home;