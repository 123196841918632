import React from 'react';

import './RightButtonOvalButton.scss';

const RightButtonOvalButton = (props) => {
  return (
    <a className="RightButtonOvalButton" {...props}>
      <div className="content">
       {props.children}
      </div>
    </a>
  )
};

export default RightButtonOvalButton;
