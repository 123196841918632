import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {ParallaxProvider} from 'react-scroll-parallax';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ParallaxProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ParallaxProvider>
);
