// HomePage
import Product1 from '../assets/images/P Synthetic Ropes.jpg';
import Product2 from '../assets/images/P Marine Safety.jpg';
import Product3 from '../assets/images/P Firefight.jpg';
import Product4 from '../assets/images/P Liferafts.jpg';
import Product5 from '../assets/images/P Navigation.jpg';
import Product6 from '../assets/images/P Nautical.jpg';
import Product7 from '../assets/images/P Offshore.jpg';
import Product8 from '../assets/images/P Signals.jpg';
import Product9 from '../assets/images/P Inflatable.jpg';
import Product10 from '../assets/images/P Misc.jpg';

import Services1 from '../assets/images/S Ship Repair.jpg';
import Services2 from '../assets/images/S Automation.jpg';
import Services3 from '../assets/images/S Marine Safety.jpg';
import Services4 from '../assets/images/S Hydraulic.jpg';
import Services5 from '../assets/images/S Main Engine.jpg';
import Services6 from '../assets/images/S Bridge Nav.png';
import Services7 from '../assets/images/S Navigation.jpg';
import Services8 from '../assets/images/S Ultrasonic.jpg';
import Services9 from '../assets/images/S Underwater.jpg';

// About Page
import AboutSpecializationNavigation from '../assets/images/About/navigation.jpg';
import AboutSpecializationCommunication from '../assets/images/About/communication.jpg';
import AboutSpecializationSafety from '../assets/images/About/safety.jpg';

import AboutPartner1 from '../assets/images/About/partners/client_adaro.png';
import AboutPartner2 from '../assets/images/About/partners/client_ksa.png';
import AboutPartner3 from '../assets/images/About/partners/client_logindo.png';
import AboutPartner4 from '../assets/images/About/partners/client_mbss.png';
import AboutPartner5 from '../assets/images/About/partners/client_meratus.png';
import AboutPartner6 from '../assets/images/About/partners/client_pelita.png';
import AboutPartner7 from '../assets/images/About/partners/client_ptk.png';
import AboutPartner8 from '../assets/images/About/partners/client_soechi.png';
import AboutPartner9 from '../assets/images/About/partners/client_ssm.png';
import AboutPartner10 from '../assets/images/About/partners/client_triton.png';


const productList = [
  {
    imgSrc: Product1,
    title: 'Synthetic Fibre Ropes',
  },
  {
    imgSrc: Product2,
    title: 'Marine Safety Equipments',
  },
  {
    imgSrc: Product3,
    title: 'Firefighting Equipments',
  },
  {
    imgSrc: Product4,
    title: 'Liferafts and Lifeboats',
  },
  {
    imgSrc: Product5,
    title: 'Navigation Electronics',
  },
  {
    imgSrc: Product6,
    title: 'Nautical Instruments',
  },
  {
    imgSrc: Product7,
    title: 'Offshore and Helideck',
  },
  {
    imgSrc: Product8,
    title: 'Signals and Lights',
  },
  {
    imgSrc: Product9,
    title: 'Inflatable Boats',
  },
  {
    imgSrc: Product10,
    title: 'Misc. Equipments',
  },
];

const specializationList = [
  {
    title: 'Navigation',
    imgSrc: AboutSpecializationNavigation,
  },
  {
    title: 'Communication',
    imgSrc: AboutSpecializationCommunication,
  },
  {
    title: 'Safety',
    imgSrc: AboutSpecializationSafety,
  }
];

const servicesList = [
  {
    title: "Ship Repairs",
    description: {
      imgSrc: Services1,
      list: [
        "All types of repairs including voyage repair",
        "Detailed analysis of the project with adoptive and exible pricing",
        "Engineers with sail experience onboard vessels",
        "Prompt deliveries without compromising the quality of our work",
        "Affilated shipyards in order to oer full service to our customers, for dry dock and new building vessels",
      ]
    },
  },
  {
    title:
      "Automation Service",
    description: {
      imgSrc: Services2,
      list: [
        'Main engine remote control system',
        'Main and auxiliary engines protection system',
        'Auxiliary engine remote Start/Stop system service',
        'Automatic synchronizing of generators',
        'Engine control room auto-alarm & indication system',
        'Anchor windlass, mooring winches, auto-pilot, telegraph and re detection alarm system',
        'Boiler, refrigerator, air conditioner & oil water separator repairs and services'
      ]
    },
  },
  {
    title: "Marine Safety Service",
    description: {
      imgSrc: Services3,
      list: [
        'Portable, movable re extinguisher annual inspection, recharge, pressure test',
        'Fixed CO2, foam, dry powder and other gas system cylinder weight checking, recharging, line blow through, pressure test',
        'Foam analysis',
        'Gas detectors calibration',
        'BA and EEBD annual inspection recharging and pressure test',
        'Immersion suits annual inspection',

      ]
    },
  },
  {
    title: "Hydraulic Equipments",
    description: {
      imgSrc: Services4,
      list: [
        'Various kinds of hydraulic motors and pumps test and recondition',
        'Hydraulic system test and repairs, hydraulic spare parts',
      ]
    },
  },
  {
    title: "Main Engine & AUX Engine",
    description: {
      imgSrc: Services5,
      list: [
        'Main engine/AUX engine cylinder head, piston rod, crankshaft, piston rings, valve seat and valve rod recondition',
        'Turbo-charger spares, purier spares Second hand spares supply',
      ]
    },
  },
  {
    title: "Bridge Navigational Watch Alarm System",
    description: {
      imgSrc: Services6,
      list: [
        'We cooperate with one of the best electrical companies in China to procure, supply, install and test, bridge navigational watch alarm system (BNWAS) according to new SOLAS rules',
        'BNWAS I to monitor bridge activity and the system monitors the awareness of the officer of the watch (OOW)',
        'It automatically alerts the back-up officer (On duty), if the “reset” button is not activated after a set value. It also may provide the crew a push button for immediate, if required. Our BNWAS has all class approved certicates',

      ]
    },
  },
  {
    title: "Navigation & Communication Service",
    description: {
      imgSrc: Services7,
      list: [
        'Radar, Mhf/Vhf, Eco sunder, Speed log, Wind indicator, Gyro Compass/Magnetic Compass, S-VDR, Radio Survey etc annual inspection and repairs',
      ]
    },
  },
  {
    title: "Ultrasonic Test",
    description: {
      imgSrc: Services8,
      list: [
        'Radar, Mhf/Vhf, Eco sunder, Speed log, Wind indicator, Gyro Compass/Magnetic Compass, S-VDR, Radio Survey etc annual inspection and repairs'
      ]
    },
  },
  {
    title: "Underwater Service",
    description: {
      imgSrc: Services9,
      list: [
        'We can offer various approved underwater service.',
        'Inspection, cleaning, cctv, plug of leakage, welding',
      ]
    },
  }
]

const contactUsProductList = [
  "Synthetic Fibre Ropes",
  "Marine Safety Equipments",
  "Fireghting Equipments",
  "Liferafts and Lifeboats",
  "Navigation Electronics",
  "Communication Electronics",
  "Nautical Instruments",
  "Oshore and Helideck",
  "Signals and Lights",
  "Inatable Boats",
  "Leisure Boats and Yachts",
  "Misc. Equipments",
];


const aboutSpecialization = [
  {
    image: AboutSpecializationNavigation,
    title: 'Marine Navigation',
    description: 'As experts in navigation, PT GABRIEL INTI MARINDO offers arange of products & services to ensure a safe and smooth sailing journey for all its client vessels.'
  },
  {
    image: AboutSpecializationCommunication,
    title: 'Radio Communication',
    description: 'PT GABRIEL INTI MARINDO provides high-quality radio communication products for marine and shipping vessels, and crew training for effective and efficient communication devices utilization.'
  },
  {
    image: AboutSpecializationSafety,
    title: 'Safety',
    description: 'PT GABRIEL INTI MARINDO strictly provides a range of safety goods with thorough training on procedures and emergency response according to IMPA and ISSA catalog descriptions.'
  },
];
const aboutPartnersImages = [
  {img: AboutPartner1, alt: 'adaro'},
  {img: AboutPartner2, alt: 'ksa'},
  {img: AboutPartner3, alt: 'logindo'},
  {img: AboutPartner4, alt: 'mbss'},
  {img: AboutPartner5, alt: 'meratus'},
  {img: AboutPartner6, alt: 'pelita'},
  {img: AboutPartner7, alt: 'ptk'},
  {img: AboutPartner8, alt: 'soechi'},
  {img: AboutPartner9, alt: 'ssm'},
  {img: AboutPartner10, alt: 'triton'},
]


const servicesSlides = [
  {
    imgSrc: Services1,
    title: 'Ship Repairs',
    desc: 'Routine maintenance, emergency repairs, and major overhauls with expertise in repairing hulls, engines, and electrical systems, as well as installing new equipment and upgrading existing systems.',

  },
  {
    imgSrc: Services2,
    title: 'Automation Service',
    desc: 'Installation, maintenance, and repair of automation equipment such as control systems, sensors, and monitoring systems to optimize their operations to reduce fuel consumption, improve performance, and minimize downtime.',
  },
  {
    imgSrc: Services3,
    title: 'Marine Safety Service',
    desc: 'Safety inspections, installation, maintenance, and repair of safety equipment such as life rafts, life jackets, and fire extinguishers to ensure that any situation that may arise are manageable.',
  },
  {
    imgSrc: Services4,
    title: 'Hydraulic Equipments',
    desc: 'Installation, maintenance, and repair of hydraulic systems and components with expertise in repairing pumps, motors, and cylinders, as well as designing and installing new hydraulic systems for all types of vessels.',
  },
  {
    imgSrc: Services5,
    title: 'Main Engine & AUX Engine',
    desc: 'Engine and auxiliary engine services are designed to help vessels maintain peak performance and efficiency. Installation, maintenance, and repair of all types of engines, including diesel, gas, and electric done by expertise in repairing and upgrading engine components.',
  },
  {
    imgSrc: Services6,
    title: 'Bridge Navigational Watch Alarm System',
    desc: 'Installation, maintenance, and repair of bridge navigational watch alarm systems to ensure that vessels are equipped with the necessary equipment to ensure safe navigation.',
  },
  {
    imgSrc: Services7,
    title: 'Navigation & Communication Service',
    desc: 'Installation, maintenance, and repair of navigation and communication equipment such as GPS systems, VHF radios, and radar systems to ensure that navigation and communication equipment is effective and efficient.',
  },
  {
    imgSrc: Services8,
    title: 'Ultrasonic Test',
    desc: 'Designed to help vessels identify and address potential issues with their equipment and systems for a wide range of components, including hulls, tanks, and pipes.',
  },
  {
    imgSrc: Services9,
    title: 'Underwater Service',
    desc: 'Inspection, maintenance, and repair of underwater components, and also surveys and assessments of underwater equipment and systems.',
  },
];

const officeAddresses = [
  {
    name: 'Headquarter Office',
    address: 'JL. Cengkeh No.14B, RT.7/RW.7, Pinangsia, Kec. Taman Sari, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11110',
  },
  {
    name: 'Branch Office',
    address: 'Ruko Senapati Land Blok A/17., Jl. Bridjen M, Yoenoes, Bypass Kendari, Kota Kendari, Sulawesi Tenggara',
  }
];
const API_STATUS_STATE = {
  isLoading: false,
  isFetched: false,
  isError: false,
  errorMessage: '',
}

const WHATSAPP_NUMBER = '+6287877668899';
const TOKOPEDIA_LINK = 'https://www.tokopedia.com/marindopt';

export {
  productList,
  servicesList,
  contactUsProductList,
  specializationList,
  aboutSpecialization,
  aboutPartnersImages,
  servicesSlides,
  officeAddresses,
  API_STATUS_STATE,
  WHATSAPP_NUMBER,
  TOKOPEDIA_LINK
};
