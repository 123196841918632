import React from 'react';
import ContactUSJPG from '../../assets/images/contact-us.jpg';
import LogoWhite from '../../assets/images/logo-white.png';
import WhatsappPNG from '../../assets/icons/whatsapp.png';
import TokopediaPNG from '../../assets/icons/tokopedia.png';

import ImageWithOverlay from '../../components/ImageWithOverlay/ImageWithOverlay';
import UnderlinedGradientButton from '../../components/UnderlinedGradientButton/UnderlinedGradientButton';
import {TOKOPEDIA_LINK, WHATSAPP_NUMBER, contactUsProductList, officeAddresses} from '../../constants';
import {mainNavigation} from '../../constants/navigation';
import {AiFillPrinter, AiOutlinePhone, AiOutlineWhatsApp} from 'react-icons/ai';

import './Footer.scss';
import {NavLink, useLocation} from 'react-router-dom';

const Footer = (props) => {
  const pathname = useLocation().pathname
  return (
    <div id='Footer'>
      <ImageWithOverlay src={ContactUSJPG} alt="contact-us" overlayClassName="footer-overlay">
        <div className='container'>
          <div className='footer-logo'>
            <img src={require('../../assets/icons/logo.png')} />
            <p className="body font-bold footer-logo-text">
              <span>MARINDO JAYA</span>
              <span> | </span>
              <span className='font-regular'>PT GABRIEL INTI MARINDO</span>
            </p>
          </div>
          <div className="footer-content">
            <div className='office-address-container'>
              <b>Office:</b>
              <p>
                <b>{officeAddresses[0].name}</b> <br />
                {officeAddresses[0].address}
              </p>
              <p>
                <b>{officeAddresses[1].name}</b> <br />
                {officeAddresses[1].address}
              </p>
            </div>
            <div className="separator"></div>
            <div className='contact-us-container'>
              <div className='body font-bold'>Get in Touch:</div>
              <a href={`https://wa.me/${WHATSAPP_NUMBER}`} target='_blank' rel='noopener' className="contact-btn">
                <AiOutlineWhatsApp size={"24px"} color='white' /> <span>Whatsapp</span>
              </a>
              <a href={TOKOPEDIA_LINK} target='_blank' rel='noopener' className="contact-btn">
                <img src={TokopediaPNG} /> <span>Tokopedia</span>
              </a>
              <span className="contact-btn">
                <AiOutlinePhone size={"24px"} color='white' /> <span>Phone: (021) 6930202</span>
              </span>
              <span className="contact-btn">
                <AiFillPrinter size={"24px"} color='white' /> <span>Fax: (021) 6908910</span>
              </span>
            </div>
            <div className="separator"></div>
            <div className='footer-navigation-container'>
              <div className='body font-bold'>Site Map</div>
              <div className="navigation-list">
                {mainNavigation.map(({to, name}, i) => {
                  return (
                    <NavLink to={to} key={i} >
                      <UnderlinedGradientButton active={pathname === to}>
                        {name}
                      </UnderlinedGradientButton>
                    </NavLink>
                  )
                })}
              </div>
            </div>
          </div>


        </div>
        <div className="footer-copyright container">
          <p>Copyright © 2023 MARINDO JAYA All Rights Reserved.</p>
        </div>
      </ImageWithOverlay >
    </div >

  )
};

export default Footer;