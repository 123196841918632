import endpoints from "../constants/endpoint"

const getBrands = () => {
  return {
    method: 'GET',
    url: endpoints.BRANDS,
  }
}

export {
  getBrands,
}