import React, {useState} from 'react';
import OverlayBG from '../../../../assets/images/homepage.jpg';

import {aboutSpecialization} from '../../../../constants';
import ImageWithOverlay from '../../../../components/ImageWithOverlay/ImageWithOverlay';

import './Specialization.scss';

const Specialization = () => {
  const [activeSpecializationIndex, setActiveSpecializationIndex] = useState(0)
  return (
    <section className="Specialization">
      <ImageWithOverlay src={aboutSpecialization[activeSpecializationIndex].image} overlayClassName={'specialization-bg-overlay'}>
        <div className="container">
          <div className="product-text heading1 font-extrabold">OUR SPECIALIZATION</div>
          <div className="specialization-box-container">
            {aboutSpecialization.map(({image, title, description}, index) => {
              return (
                <div className="specialization-box" key={index} onMouseEnter={() => {setActiveSpecializationIndex(index)}}>
                  <div className="specialization-img">
                    <img src={image} alt={title} />
                  </div>
                  <div className="title-text subtitle2 font-semibold">{title}</div>
                  <p className='body'>{description}</p>
                </div> 
              )
            })}
          </div>
        </div>
      </ImageWithOverlay>
    </section>
  )
};

export default Specialization;
