import endpoints from "../constants/endpoint"

const getProducts = (query) => {
  const defaultQuery = {
    size: 15,
    page: 1,
    sortBy: 'asc',
  }
  return {
    method: 'GET',
    url: endpoints.PRODUCTS,
    query: {
      ...defaultQuery,
      ...query,
    }
  }
};

export {
  getProducts,
}