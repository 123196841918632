import React from 'react';
import {ParallaxBanner} from 'react-scroll-parallax';
import PageWrapper from '../../Layout/PageWrapper/PageWrapper';
import CustomScrollbar from '../../components/CustomScrollbar/CustomScrollbar';

import CompanyProfile from './sections/CompanyProfile/CompanyProfile';
import Specialization from './sections/Specialization/Specialization';
import ClientAndPartners from './sections/ClientAndPartners/ClientAndPartners';

import './About.scss';
import ParallaxMainHeadline from '../../components/ParallaxMainHeadline/ParallaxMainHeadline';
import AboutHeadlineJPG from '../../assets/images/Image-aboutpage-header.jpg'
const About = () => {
  return (
    <PageWrapper>
      <CustomScrollbar>
        <div className="About">
          <ParallaxMainHeadline imgSrc={AboutHeadlineJPG} imgAlt={'about-img'} name="ABOUT US" />
          <CompanyProfile />
          <Specialization />
          <ClientAndPartners />
        </div>
      </CustomScrollbar>
    </PageWrapper>
  )

};

export default About;