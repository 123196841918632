import endpoints from "../constants/endpoint"

const getCategories = () => {
  return {
    method: 'GET',
    url: endpoints.CATEGORIES,
  }
};

export {
  getCategories,
}