import React from 'react';
import {aboutPartnersImages} from '../../../../constants';

import './ClientAndPartners.scss';

const ClientAndPartners = (props) => {
  return (
    <section className="ClientAndPartners">
      <div className="product-text heading1 font-extrabold">
        CLIENTS & PARTNERS
      </div>
      <div className="container partner-row">
        {aboutPartnersImages.map(({img, alt}, i) => {
          return (
            <div className="partner-item" key={i}>
              <img src={img} alt={alt} />
            </div>
          )
        })}
      </div>
      <div className="and-more body font-bold">
        And More...
      </div>
    </section>
  )
};

export default ClientAndPartners;