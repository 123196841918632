import React, {useEffect, useRef, useState} from 'react';
import PageWrapper from '../../Layout/PageWrapper/PageWrapper';
import ParallaxMainHeadline from '../../components/ParallaxMainHeadline/ParallaxMainHeadline';
import OverlayedButton from '../../components/OverlayedButton/OverlayedButton';
import {formatPhoneNumber} from '../../utils/common';
import ContactUsHeadlineJPG from '../../assets/images/Image-contactpage-header.jpg'

import './Contact.scss';
import CustomScrollbar from '../../components/CustomScrollbar/CustomScrollbar';
import {officeAddresses} from '../../constants';
import {sendEmail} from '../../utils/email';

const Contact = () => {

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const timeoutRef = useRef(null);

  const [isValidPhone, setIsValidPhone] = useState(true);

  const resetToDefault = () => {
    setName('');
    setEmail('');
    setContactNumber('');
    setSubject('');
    setMessage('');
    setIsValidPhone(true);
  }

  const sendAnEmail = async () => {
    setIsLoadingSubmit(true);
    try {
      await sendEmail(name, email, contactNumber, subject, message);
      setIsSent(true);
    } catch (error) {
      window.alert('Sorry, Something went wrong!');
    }
    resetToDefault();
    setIsLoadingSubmit(false);
  }
  const onSubmitForm = (e) => {
    e.preventDefault();
    sendAnEmail();
  }
  useEffect(() => {
    if (isSent && !isLoadingSubmit) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setIsSent(false);
      }, 3000);
    }
  }, [isSent, isLoadingSubmit])
  return (
    <PageWrapper>
      <CustomScrollbar>
        <div className="Contact">
          <ParallaxMainHeadline
            imgSrc={ContactUsHeadlineJPG}
            imgAlt={'contact-img'}
            name="CONTACT"
          />
          <div className="container address-container">

            <div className="heading1 font-extrabold">VISIT US</div>
            <div className="address-detail">
              <div className="body font-bold">{officeAddresses[0].name}</div>
              <div className="body font-regular">{officeAddresses[0].address}</div>
            </div>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed/v1/place?q=Jl.+Cengkeh+No.14b,+RT.7/RW.7,+Pinangsia,+West+Jakarta+City,+Jakarta,+Indonesia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
              </iframe>
            </div>

            <div className="address-detail">
              <div className="body font-bold">{officeAddresses[1].name}</div>
              <div className="body font-regular">{officeAddresses[1].address}</div>
            </div>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed/v1/place?q=Jalan+Brigjen+M.+Joenoes,+Lepo-Lepo,+Kendari+City,+South+East+Sulawesi,+Indonesia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
              </iframe>
            </div>
            <div className="address-info-form-wrapper">
              <div className="address-information-container">
                <div className="body font-bold">Opening Hours:</div>
                <div className="body font-bold">Office Hour (Jakarta & Kendari):</div>
                <div className="body font-regular">Monday to Friday: <br />9.00 - 16.30</div>
                <div className="body font-regular">Saturday: <br />9.00 - 14.00</div>
                <div className="body font-regular">Sunday: <br />Closed</div>


                <div className="body font-bold">Contact Information:</div>
                <div className="body font-regular">No HP: (+62) 878 7766 8899</div>
                <div className="body font-regular">Tel: (021) 6930202 / Fax (021) 6908910</div>
                <div className="body font-regular">Email: <br />
                  marindoopt@cbn.net.id<br />
                  marindojaya.id@gmail.com
                </div>
              </div>
              <div className="form-separator"></div>
              <div className="message-form">
                <div className="body font-bold">Message Us</div>
                <form onSubmit={onSubmitForm} className='message-us-form'>
                  <label htmlFor="name">Name</label>
                  <input
                    type='text'
                    value={name}
                    required
                    onChange={e => setName(e.target.value)}
                    disabled={isLoadingSubmit}
                  />
                  <label htmlFor="email-address">Email Adddress</label>
                  <input
                    type='email'
                    value={email}
                    required
                    onChange={e => setEmail(e.target.value)}
                    disabled={isLoadingSubmit}
                  />
                  <label htmlFor="contact-number">Contact Number</label>
                  <input
                    type='text'
                    value={contactNumber}
                    placeholder='+62 (optional)'
                    maxLength={13}
                    onChange={event => {
                      const {value} = event.target;
                      const digitsOnly = value.replace(/[^\d]/g, ''); // remove non-digits
                      const formattedNumber = formatPhoneNumber(digitsOnly);
                      setContactNumber(formattedNumber);
                      // if (PHONE_NUMBER_REGEX.test(digitsOnly)) {
                      //   setIsValidPhone(true)
                      // } else {
                      //   setIsValidPhone(false)
                      // }
                    }}
                    disabled={isLoadingSubmit}
                  />
                  <label htmlFor="email-subject">Subject</label>
                  <input
                    type='text'
                    value={subject}
                    required
                    onChange={e => setSubject(e.target.value)}
                    disabled={isLoadingSubmit}
                  />
                  <label htmlFor="email-message">Message</label>
                  <textarea
                    value={message}
                    required
                    onChange={e => setMessage(e.target.value)}
                    disabled={isLoadingSubmit}
                    rows={9}
                  />
                  {isSent && <label className="body font-regular"> Email is sent! We will reach out to you soon </label>}
                  <OverlayedButton disabled={isLoadingSubmit}>
                    <span className="body font-bold">Submit</span>
                  </OverlayedButton>
                </form>
              </div>
            </div>

          </div>
        </div>
      </CustomScrollbar>
    </PageWrapper>
  )

};

export default Contact;