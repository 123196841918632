import React from 'react';
import './UnderlinedGradientButton.scss';

const UnderlinedGradientButton = (props) => {

  return (
    <div className="UnderlinedGradientButton" href={props.href} >
      <div className="btn-content">
        {props.children}
      </div>
      <div className={`bot-border ${props.active ? 'active-border' : ''}`}></div>
    </div>
  )
};

export default UnderlinedGradientButton;