import React, {useState} from 'react';
import ProductItem from '../../../../components/ProductItem/ProductItem';
import ModalPopup from '../../../../components/ModalPopup/ModalPopup';
import ProductDetail from '../../../../components/ProductDetail/ProductDetail';
import Skeleton from 'react-loading-skeleton';
import './ProductList.scss';

const ProductList = (props) => {
  const {isLoading = false, data = []} = props;

  const [isOpen, setIsOpen] = useState(false);
  const [productDetail, setProductDetail] = useState({
    type: '',
    material: '',
    brandName: '',
    description: '',
    price: 0,
    link: '',
    images: []
  });
  const DEFAULT_IMG = 'https://placehold.co/100?text=no-image';

  const getImgRequire = (imageName = '') => {
    try {
      return require(`../../../../assets/product-images/${imageName}`)
    } catch (error) {
      return DEFAULT_IMG;
    }
  }
  const showModal = (isShow, data) => (e) => {
    e.stopPropagation();
    setIsOpen(isShow);
    const images = data.images || [];
    setProductDetail({
      name: data.name || '',
      type: data.model || '',
      material: data.material || '',
      brandName: data.brandName || '',
      description: data.descriptionText || '',
      price: data.price || 0,
      link: data.purchaseLink || '',
      images: images?.length < 1 ? [
        DEFAULT_IMG,
      ] :
        images.map(imageName => {
          return getImgRequire(imageName);
        })
    });
  }

  const _renderProducts = () => {
    return (
      <React.Fragment>
        {data.map((eachData) => {
          const dataImageName = eachData.images?.[0];
          return (
            <ProductItem
              key={eachData.id}
              name={eachData.name}
              onClick={showModal(true, eachData)}
              imgSrc={dataImageName ? getImgRequire(dataImageName) : 'https://placehold.co/100?text=no-image'}
            />
          )
        })}

      </React.Fragment>
    )
  }

  const _renderSkeletons = () => {
    return (
      <React.Fragment>
        {
          new Array(15).fill(0).map((e, i) => {
            return (
              <Skeleton key={i} height={'100%'} containerClassName="skeleton-container" />
            )
          })
        }
      </React.Fragment>
    )
  }

  const renderProducts = () => {
    if (isLoading) {
      return _renderSkeletons(); 
    } else {
      if (data.length) {
        return _renderProducts();
      } else {
        return <div className='subtitle3 font-semibold'>No Data Found</div>
      }
    }
  }
  return (
    <section className="ProductList">

      <div className="product-grid">
        {renderProducts()}
        <ModalPopup
          show={isOpen}
          onClickOutside={() => setIsOpen(false)}
          onClick={() => setIsOpen(true)}
        >
          <ProductDetail
            onClickCloseBtn={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
            name={productDetail.name}
            spesification={{
              Type: productDetail.type,
              Bahan: productDetail.material,
              Merk: productDetail.brandName,
            }}
            description={productDetail.description}
            price={productDetail.price}
            link={productDetail.link}
            images={productDetail.images}

          />
        </ModalPopup>
      </div>
    </section>
  )
};

export default ProductList;