import React from 'react';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import OverlayedButton from '../../../../components/OverlayedButton/OverlayedButton';
import Skeleton from 'react-loading-skeleton';
import './Category.scss';

const Category = (props) => {
  const {
    isLoading = false,
    list = [],
    selectedCategory = null,
    onClickCategory = () => {},
  } = props;

  const _renderCategoryList = (categoryList) => {
    const categoryNodes = [];
    categoryList.forEach(({subCategories, ...category}, index) => {
      const isSelectedCategory = selectedCategory.category === category.id;
      categoryNodes.push(
        <OverlayedButton
          key={`${category}-${category?.id}-${index}`}
          borderTop
          className={`category body ${isSelectedCategory ? 'category-active' : ''}`}
          active={isSelectedCategory}
          onClick={() => {
            const shouldClose = isSelectedCategory && selectedCategory.category;
            const newCategory = shouldClose ? null : category?.id;
        
            onClickCategory({
              category: newCategory,
              subCategory: categoryList[index].subCategories[0]?.id
            });
          }}
        >
          {category?.name}
        </OverlayedButton>
      );
      const subCategoryNodes = [];
      subCategories.forEach((subCat, i) => {
        const isSelected = selectedCategory.subCategory === subCat.id;
        subCategoryNodes.push(
          <div
            key={`${subCat}-${subCat?.id}-${i}`}
            className={`sub-category body ${isSelected ? 'sub-category-active' : ''}`}
            onClick={() => {
              onClickCategory({
                category: category?.id,
                subCategory: subCat?.id
              });
            }}>
            {subCat?.name}
          </div>
        )
      });
      categoryNodes.push(
        <Dropdown
          key={index}
          className="sub-category-container"
          active={isSelectedCategory}
        >
          {subCategoryNodes}
        </Dropdown>
      )
    })

    return categoryNodes;
  }

  return (
    <section className="Category">
      <div className='category-title subtitle3 font-bold'>Categories</div>
      <div >
      </div>
      {isLoading ?
        <Skeleton
          height={35}
          count={12}
        /> :
        <div className="category-list">
          {_renderCategoryList(list)}
        </div>
      }

    </section>
  )
};

export default Category;
