import axios from 'axios';
// const client = new SMTPClient({
//   user: 'webmailer@marindo-jaya.com',
//   password: 'webmailer123',
//   host: 'srv68.niagahoster.com',
//   ssl: true,
//   port: 465,
// });

export const sendEmail = async (
  name = '',
  email = '',
  phone = '',
  subject = '',
  msg = '',
) => {
  const SERVICE_ID = 'service_uabiemw';
  const EMAIL_TEMPLATE_ID = 'template_yx0ctz4';
  const PUBLIC_KEY = 'uKR2o6yJksPliA4ng';

  return await axios.post('https://api.emailjs.com/api/v1.0/email/send', {
    service_id: SERVICE_ID,
    template_id: EMAIL_TEMPLATE_ID,
    user_id: PUBLIC_KEY,
    template_params: {
      name,
      email,
      phone,
      subject,
      message: msg
    }
  })
};


