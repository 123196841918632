import React, {useRef} from 'react';
import './CustomProgressBar.scss';
import {useEffect} from 'react';
import {useState} from 'react';

const CustomProgressBar = (props) => {
  const {totalPage = 5, activePage = 0} = props;

  const containerRef = useRef();
  const barRef = useRef();

  useEffect(() => {
    const totalBarWidth = containerRef.current.clientWidth;
    const multiplier = activePage < 0 ? 0 : activePage;
    const barWidth = totalBarWidth / totalPage;
    const leftPos = barWidth * multiplier;
    barRef.current.style.left = `${leftPos}px`;
    barRef.current.style.width = `${barWidth}px`;
  }, [totalPage, activePage])


return (
  <div className="CustomProgressBar" ref={containerRef}>
    <div className="CustomProgressBar-bar" ref={barRef}></div>
  </div>
)
};

export default CustomProgressBar;