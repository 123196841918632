import React from 'react';
import './OverlayedButton.scss';

const OverlayedButton = (props) => {
  const {
    borderAll = false,
    borderBottom = false,
    borderTop = false,
    borderLeft = false,
    borderRight = false,
    active = false,
    onClick = () => { },
    className = '',
    ...otherProps
  } = props;
  return (
    <button
      className={`
        OverlayedButton 
        body 
        ${(borderBottom || borderAll) ? 'OverlayedButton__border-btm' : ''}
        ${(borderTop || borderAll) ? 'OverlayedButton__border-top' : ''}
        ${(borderLeft || borderAll) ? 'OverlayedButton__border-left' : ''}
        ${(borderRight || borderAll) ? 'OverlayedButton__border-right' : ''}
        ${active ? 'OverlayedButton__selected' : ''}
        ${className}
      `}
      disabled={props.disabled}
      onClick={onClick}
      {...otherProps}
    >
      {otherProps.children}
    </button>
  )
}

export default OverlayedButton;
