import React, {useRef} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import './CustomScrollbar.scss';

const CustomScrollbar = (props) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const childrenRef = useRef();
  const hiddenRef = useRef(true);
  const scrollBarRef = useRef();
  const barHeight = 50;

  const handleScroll = () => {
    const scrollBarHeight = scrollBarRef?.current?.clientHeight;

    const scrollPos = window.pageYOffset;
    const maxHeight = childrenRef?.current?.scrollHeight;

    const PADDING = Math.round(scrollBarHeight * 20 / 100);

    const scrollPercent = Math.round(scrollPos * scrollBarHeight / maxHeight)
    const reachedBottom = scrollPercent >= (scrollBarHeight - PADDING);
    const reachedTop = scrollPercent <= (PADDING - barHeight)

    setScrollPercentage(scrollPercent);

    hiddenRef.current = reachedBottom || reachedTop;
  };

  const isHidden = hiddenRef.current;
  useEffect(() => {
    window.addEventListener('DOMContentLoaded', handleScroll);
    window.addEventListener('scroll', handleScroll, false);

    return () => {
      window.addEventListener('DOMContentLoaded', handleScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id='child-content' ref={childrenRef}>
      <div className={`CustomScrollbar ${isHidden ? 'hidden' : ''}`}>
        <div className='text'>
          Get to {" "}
          <span>
            know Us
          </span>
        </div>
        <div className="scrollbar" ref={scrollBarRef}>
          <div className="scrollbar-active"
            style={{
              height: `${barHeight}px`,
              top: `${scrollPercentage}px`
            }}
          />
        </div>
      </div>
      {props.children}
    </div>
  )
};

export default CustomScrollbar;