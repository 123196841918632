import React from 'react';
import OverlayedButton from '../../../../components/OverlayedButton/OverlayedButton';
import Skeleton from 'react-loading-skeleton';

import './Brand.scss';
import {getRandomNumber} from '../../../../utils/common';

const Brand = (props) => {
  const {
    isLoading = false,
    brands = [],
    selectedBrands = [],
    onClickBrand = (brand) => { },
    onClickClear = () => {},
    isActiveClear = false,
  } = props;

  const _renderBrands = (_brands) => {
    return (
      <React.Fragment>
        {
          _brands.map((eachBrand) => {
            return (
              <OverlayedButton
                key={eachBrand.id}
                borderAll
                className="brand-item font-bold"
                active={selectedBrands.includes(eachBrand?.id)}
                onClick={() => {onClickBrand(eachBrand)}}
              >
                {eachBrand.name}
              </OverlayedButton>
            )
          })
        }

      </React.Fragment>
    )
  }
  return (
    <section className="Brand">
      <div className="brand-row">
        <div className='brand-title subtitle3 font-bold'>Brand</div>
        {isActiveClear && <div className='font-regular clear-btn' onClick={() => { onClickClear() }}>Clear</div>}
      </div>
      <div className="brand-item-container">
        {isLoading ? new Array(16).fill(0).map((_, i) => {
          return (
            <div className="brand-item" key={`skeleton-${i}`}>
              <Skeleton width={getRandomNumber(60, 120)} height={40} />
            </div>
          )
        }) : _renderBrands(brands)}
      </div>
    </section>
  )
};

export default Brand;