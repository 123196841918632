import React, {useCallback, useState} from 'react';
import PageWrapper from '../../Layout/PageWrapper/PageWrapper';
import ParallaxMainHeadline from '../../components/ParallaxMainHeadline/ParallaxMainHeadline';

import './Services.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useRef} from 'react';
import ImageWithOverlay from '../../components/ImageWithOverlay/ImageWithOverlay';
import CustomProgressBar from '../../components/CustomProgressBar/CustomProgressBar';
import OverlayedButton from '../../components/OverlayedButton/OverlayedButton';
import CustomScrollbar from '../../components/CustomScrollbar/CustomScrollbar';
import ServicesHeadlineJPG from '../../assets/images/Image-servicepage-header.jpg'
import {servicesSlides} from '../../constants';
import {sendEmail} from '../../utils/email';
import {useEffect} from 'react';
const Services = () => {
  const sliderRef = useRef();
  const [activeSlide, setActiveSlide] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const timeoutRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const resetForm = () => {
    setName('');
    setEmail('');
    setMessage('')
  };


  const sendAnEmail = async () => {
    setIsLoadingSubmit(true);
    try {
      await sendEmail(name, email, '', 'Request for information on the services', message);
      setIsSent(true);
    } catch (error) {
      window.alert('Sorry, Something went wrong!');
    }
    resetForm();
    setIsLoadingSubmit(false);
  }

  const onSubmitForm = (e) => {
    e.preventDefault();
    sendAnEmail();
  }
  const sliders = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

  useEffect(() => {
    if (isSent && !isLoadingSubmit) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setIsSent(false);
      }, 3000);
    }
  }, [isSent, isLoadingSubmit])
  return (
    <PageWrapper>
      <CustomScrollbar>
        <div className="Services">
          <ParallaxMainHeadline
            imgSrc={ServicesHeadlineJPG}
            imgAlt={'services-img'}
            name="SERVICES"
          />
          <div className="container">
            <div className="satisfaction-container">
              <div className="heading2 font-semibold">YOUR SAFETY IS OUR PRIORITY</div>
              <div className="body font-regular">
                <b>PT GABRIEL INTI MARINDO</b>  is committed to delivering products of the highest quality with a meticulous quality control process to ensure all products meet the industry standards
              </div>
              <div className="body font-regular">
                <b>PT GABRIEL INTI MARINDO</b> adheres to strict safety protocols and regulations while relying on safety procedures.
              </div>
            </div>
          </div>

          <Swiper
            ref={sliderRef}
            slidesPerView={1}
            className='carousel'
            initialSlide={1}
            onActiveIndexChange={(swiper) => setActiveSlide(swiper.activeIndex)}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
            }}
            centeredSlides
          >
            <div className="circle-btn-left" onClick={handlePrev} />
            {servicesSlides.map((slideContent, index) => (
              <SwiperSlide key={index}  >
                <ImageWithOverlay
                  src={slideContent.imgSrc}
                  overlayClassName={`slide-overlay${activeSlide === index ? '-active' : ''}`}
                >
                  <div className={`carousel-content ${activeSlide === index ? 'carousel-content-active' : ''}`}>
                    <div className='heading1 font-bold slide-title'>{slideContent.title}</div>
                    <div className='body font-regular slide-body'>{slideContent.desc}</div>
                  </div>
                </ImageWithOverlay>
              </SwiperSlide>
            ))}
            <div className="circle-btn-right" onClick={handleNext} />
          </Swiper>

          <div className="container progress-bar-container">
            <div className="progress-bar">
              <CustomProgressBar activePage={activeSlide} totalPage={sliders.length} />
            </div>
          </div>

          <div className="container">
            <form onSubmit={onSubmitForm} className='email-input-form'>
              <div className="subtitle3 font-semibold">For more information on the services we offer, please contact us:</div>
              <input
                className='name-input'
                type='text'
                placeholder='Name'
                value={name}
                required
                onChange={e => setName(e.target.value)}
                disabled={isLoadingSubmit}
              />
              <input
                className='email-input'
                value={email}
                placeholder='Email Address'
                type='email'
                required
                onChange={e => setEmail(e.target.value)}
                disabled={isLoadingSubmit}
              />
              <textarea
                className='message-input'
                placeholder='Message'
                value={message}
                required
                onChange={e => setMessage(e.target.value)}
                disabled={isLoadingSubmit}
                rows={9}
              />
              {isSent && (
                <>
                  <div className="font-regular">Email is sent! We will reach out to you soon</div> <br />
                </>
              )}

              <OverlayedButton disabled={isLoadingSubmit} active>{isLoadingSubmit ? "Sending..." : "Submit" }</OverlayedButton>
            </form>
          </div>
        </div>
      </CustomScrollbar>
    </PageWrapper>
  )

};

export default Services;