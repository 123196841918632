import axios from 'axios';

const callApi = async ({
  method = 'GET',
  url,
  query,
  params,
  headers,
  body,
}) => {
  try {
    const config = {
      method,
      url,
      params,
      headers,
      data: body,
    };

    if (method === 'GET') {
      config.params = query;
    } else {
      config.data = body;
    }

    const {data, ...otherConfig} = await axios(config);
    const results = {
      data: data.data,
      ...otherConfig,
    }
    return results;
  } catch (error) {
    throw new Error(error.response?.data || 'An error occurred');
  }
};

export default callApi;