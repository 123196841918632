export const classNameCallback = (className) => ({isActive}) => {return isActive ? ['active', className].join(' ') : className}

export const currencyFormat = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0
  }).format(number);
}

export const PHONE_NUMBER_REGEX = /^(\+62|62|0)[1-9][0-9]{6,10}$/;
export const formatPhoneNumber = (phoneNumber) => {
  phoneNumber = phoneNumber.replace(/[^\d]/g, '');
  phoneNumber = `+62${phoneNumber.slice(2)}`;

  return phoneNumber;
}

export const shortenLongNum = num => {
  num = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return num;
  }
  let si = [
    {v: 1E3, s: "K"},
    {v: 1E6, s: "M"},
    {v: 1E9, s: "B"},
    {v: 1E12, s: "T"},
    {v: 1E15, s: "P"},
    {v: 1E18, s: "E"}
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
};

export const getRandomNumber = (min = 1, max = 20) => {
  return Math.floor(Math.random() * (max - min) + min)
}