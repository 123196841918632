import React from 'react';
import './ImageWithOverlay.scss';

const ImageWithOverlay = (props) => {

  return (
    <div className={`ImageWithOverlay ${props.className}`}>
      <div className={`overlay-container ${props.overlayClassName || ''}`}></div>
      {props.src && <img className='ImageWithOverlay-overlayimg' src={props.src} alt={props?.alt} />}
      <div className="ImageWithOverlay-content">
        {props.children}
      </div>
    </div>
  )
};


export default ImageWithOverlay;