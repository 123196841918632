import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import ImageWithOverlay from '../../components/ImageWithOverlay/ImageWithOverlay';
import Footer from '../Footer/Footer';
import ContactUSJPG from '../../assets/images/contact-us.jpg';
import useWindowSize from '../../hooks/useWindowSize';

import './PageWrapper.scss';

const PageWrapper = (props) => {
  const footerRef = useRef(null);

  const [windowW] = useWindowSize();

  useEffect(() => {
    let footerHeight = footerRef.current.offsetHeight;
    const pageContainer = document.querySelector(".page-wrapper-container");
    const navbarHeight = document.getElementsByClassName("navbar")[0].offsetHeight || 40;
    if (windowW < 768) {
      footerHeight = 0;
    }
    // console.log(footerHeight)
    pageContainer.style.marginBottom = `${footerHeight}px`;
    pageContainer.style.marginTop = `${navbarHeight}px`
  })
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
      <div className="PageWrapper">
        <div className="page-wrapper-container">
          {props.children}
          <div className="absolute-scroll-top">
            <div className="btn" onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
            }}>
              <div className="circle-btn-top"></div>
            </div>
          </div>
        </div>
        <div className="bottom-container" ref={footerRef}>
          <Footer />
        </div>
      </div>
  )
};

export default PageWrapper;