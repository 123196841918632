import React from 'react';
import {CSSTransition} from 'react-transition-group';

import './Dropdown.scss';

const Dropdown = props => {
  const {active = false} = props;

  return (
    <div className={`Dropdown ${props.className || ''}`}>
      <CSSTransition
        in={active}
        classNames={"dropdown-content"}
        timeout={400}
        unmountOnExit
      >
        <div className="dropdown-content">
          {props.children}
        </div>
      </CSSTransition>
    </div>
  )
};

export default Dropdown;

