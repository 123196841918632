import React from 'react';

import './ProductItem.scss';

const ProductItem = (props) => {
  const {
    imgSrc,
    name = "Product Name",
    alt,
    onClick,
  } = props;
  return (
    <div className="ProductItem" onClick={onClick}>
      <div className="product-img">
        <div className="product-bg-overlay" />
        <img src={imgSrc} alt={alt} />
      </div>
      <div className="name body font-bold">{name}</div>

    </div>
  )
}

export default ProductItem;
