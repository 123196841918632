import React from 'react';
import './ProductDetail.scss';
import {currencyFormat} from '../../utils/common';
import {useState} from 'react';

const ProductDetail = (props) => {

  const {
    name = '',
    spesification = {
      Type: '',
      Bahan: '',
      Merk: '',
    },
    description = '',
    price = 0,
    link = '',
    images = ['https://placehold.co/100?text=no-image'],
    onClickCloseBtn
  } = props;
  const PREVIEW_RANGE_INDEX = 4;
  const [selectedImage, setSelectedImage] = useState({
    startIdx: 0,
    endIndex: images.length >= PREVIEW_RANGE_INDEX ? PREVIEW_RANGE_INDEX - 1 : images.length - 1,
    selectedIdx: images.length > 0 ? 0 : null
  });

  const lines = description.split('\n');

  return (
    <div className="ProductDetail">
      <div className="close-popup-btn font-weight-bold body" onClick={(e) => onClickCloseBtn(e)}>X</div>
      <div className="images-container">
        <div className="preview">
          <img src={images[selectedImage.selectedIdx]} alt={`images-${selectedImage.selectedIdx}`} />
        </div>
        <div className="img-list">
          <div className="circle-btn-left" onClick={() => {
            setSelectedImage(prev => {
              const start = prev.startIdx - 1;
              const end = prev.endIndex - 1;
              const nextSelectedIdx = prev.selectedIdx - 1;

              const canPrev = start >= 0;

              const inBetweenRange = nextSelectedIdx >= prev.startIdx && nextSelectedIdx <= prev.endIndex;
              if (inBetweenRange) {
                return ({
                  ...prev,
                  selectedIdx: inBetweenRange ? nextSelectedIdx : prev.selectedIdx
                })
              } else {
                if (!canPrev) {
                  return prev;
                } else {
                  return ({
                    ...prev,
                    startIdx: start,
                    endIndex: end,
                    selectedIdx: nextSelectedIdx
                  })
                }
              }
            });
          }}></div>
          {images.map((imageSrc, i) => {
            const inBetweenRange = i >= selectedImage.startIdx && i <= selectedImage.endIndex;
            // console.log(inBetweenRange, `currIndex: ${i}`, selectedImage)
            return (
              <div
                key={i}
                className="img-small"
                is-selected={i === selectedImage.selectedIdx ? "true" : "false"}
                is-hidden={inBetweenRange ? 'false' : 'true'}
                onClick={() => {
                  setSelectedImage((selected) => ({...selected, selectedIdx: i}))
                }}
              >
                <img src={imageSrc} alt={`images-list-${i}`} />
              </div>
            )
          })}
          <div className="circle-btn-right" onClick={() => {
            setSelectedImage(prev => {
              const MAX_LENGTH = images.length > 0 ? images.length - 1 : 0;
              const start = prev.startIdx + 1;
              const end = prev.endIndex + 1;

              const canNext = end <= MAX_LENGTH;

              const nextSelectedIdx = prev.selectedIdx + 1;
              const inBetweenRange = nextSelectedIdx >= prev.startIdx && nextSelectedIdx <= prev.endIndex;
              if (inBetweenRange) {
                return ({
                  ...prev,
                  selectedIdx: inBetweenRange ? nextSelectedIdx : prev.selectedIdx
                })
              } else {
                if (!canNext) {
                  return prev;
                } else {
                  return ({
                    ...prev,
                    startIdx: start,
                    endIndex: end,
                    selectedIdx: nextSelectedIdx
                  })
                }
              }
            });
          }}></div>
        </div>
      </div>

      <div className="product-detail">
        <div className="product-name subtitle2 font-bold">{name}</div>

        <div className="product-spesification">
          <div className="body font-semibold">Spesifikasi:</div>
          {Object.keys(
            spesification
          ).map((key, i) => {
            return (
              <div key={i} className="body">{key}: {spesification[key]}</div>
            )
          })}
        </div>

        <div className="product-description">
          <div className="body font-semibold">Deskripsi:</div>
          <div className="body">
            {lines.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index !== lines.length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
        </div>
        {/* <div className="product-price subtitle2 font-bold">Price: {currencyFormat(price)}</div> */}
        {link !== '' && <a href={link} target='_blank' className="purchase-link body font-semibold">
          Buy on <span className='font-extrabold'>Tokopedia</span>
        </a>}
      </div>
    </div>
  )
};

export default ProductDetail;
